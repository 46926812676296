
.Toastify__toast {
  background-color: #333333;
  border-radius: 200px;

  width: auto;
  height: 33px;
  min-height: 33px;
  padding: 5px 10px;
}

.Toastify__toast-body {
  padding: 0;
}

.toastMsgBox{
  margin-left: 8px;

  font: normal 600 14px/1.5 Pretendard;
  color: #FFFFFF;
}
