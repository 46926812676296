.header{
  border-bottom: 1px solid #E1E1E1
}

.header_ctn{
  background-color: #ffffff;
  height: 72px;

  display: flex;
  align-items: center;

  max-width: 1440px;
  width: 100%;

  margin: 0 auto; // 가운데 정렬

  @media (max-width: 1920px) {
    padding: 24px; // 화면 크기가 1920px보다 작을 때 패딩을 24px로 설정
  }
}

.portal_logo{
  width:152px;
  height:24px;

  background: url(../../assets/images/HEALTH_SCAN_CI.svg) no-repeat left top;
}


.info_box{
  margin-left: auto;

  display: flex;
  align-items: center;
  gap: 30px;

  position: relative;

  font: normal 600 16px "Pretendard";
  color: #000000;

  line-height: 24px;

  & span{
    cursor: pointer;
  }

  & .popup {
    position: absolute;

    left: 27.5%;
    width: 200px;
    height: 58px;

    transform: translateX(-50%);

    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 5px;

    padding: 16px 32px;
    border-radius: 8px;

    background-color: #1E1E1E;

    font: normal 500 16px "Pretendard";
    color: #FFFFFF;
  }
}

.m_info_box{
  display: none;
}
.menuBtn{
  display: none;
}
.modal{
  display: none;
}

@media (max-width: 640px) {
  .info_box{
    display: none;
  }

  .info_box_m{
    display: block;
    margin-left: auto;
  }

  .menuBtn {
    display: block;

    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .modal {
    display: block;

    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    transition: width 0.3s ease;
    z-index: 999;
  }

  .modal.open {
    width: 100%;
  }

  .menuCont {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    max-width: 300px;
    height: 100%;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }

  .closeBtn {
    align-self: flex-end;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  nav ul {
    list-style: none;
    padding: 0;
  }

  nav ul li {
    margin: 15px 0;
    font-size: 18px;
    cursor: pointer;
  }


}



