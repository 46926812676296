
.sec05_cnt{
  min-height: 450px;
  max-width: 640px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 10px;
}

.sec05_cnt .sec05_title_box{

  margin: 64px 20px 0 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 10px;

  & .main_title{
    font: normal 600 22px "Pretendard";
    line-height: 33px;
    color: #000000;

    & span{
      color: #388AF7;
    }
  }

  & .sub_title{
    width: 100%;
    font: normal 400 15px "Pretendard";
    line-height: 27px;
    color: #343434;
    text-align: center;

    & span{
      font-weight: 600;
    }
  }
}
.sec05_content{
  width: 100%;
}

.sec05_content .slide_box {
  height: 570px;
  background-color: #f0f2f7;
  box-shadow: inset 0 45px #ffffff, inset 0 -60px #ffffff; /* 상하 영역 덮기 */
}

.sec05_content .slide_box .swiper{
  max-width: 1920px;
}

.sec05_content .slide_box .swiperSlide{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sec05_content .slide_box .slide_cont{
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sec05_content .slide_box .slide_cont .cont_img{

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  & div{
    width: 328px;
    height: 232px;
  }

  & .cont_img_bg01{
    background: url(../../assets/images/sec05_slide_img_m_001.png) no-repeat center top;
  }
  & .cont_img_bg02{
    background: url(../../assets/images/sec05_slide_img_m_002.png) no-repeat center top;
  }
  & .cont_img_bg03{
    background: url(../../assets/images/sec05_slide_img_m_003.png) no-repeat center top;
  }
}

.sec05_content .slide_box .slide_cont .cont_textBox{

  margin-top: 15px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  gap: 16px;

  & .messBox {

    margin-left: 22px;
    margin-right: 22px;

    //margin: 64px 50px 0 50px;

    //position: relative;
    //width: 100%;
    height: 235px;
    border-radius: 12px;
    background-color: #4264EC;

    color: #FFFFFF;
    line-height: 27px;
    padding: 24px;

    & h3{
      font: normal 700 20px "Pretendard";
    }

    & hr {
      width: 100%;
      border: none;
      border-top: 1px solid #F0F2F7; /* 두께와 색상 */
      margin: 10px 0; /* 위아래 여백 */
    }

    & p {
      font: normal 400 15px "Pretendard";
      line-height: 23px;

      & span{
        font-weight: 600;
      }
    }
  }
}


.sec05_content .swiper .nav_bar{

  margin: 60px 0 42px 0;

  font: normal 400 16px "Pretendard";
  line-height: 24px;
  color: #343434;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  gap: 10px;

  & span{
    font-weight: 600;
  }

  & button{
    border: none;
    background-color: transparent;
  }
}
