/*@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");*/

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');



/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*html, body {*/
/*  overflow-x: hidden;*/
/*}*/

/* Body styles */
html, body {
  font-family: 'Pretendard', sans-serif; /*기본 폰트 설정*/
  line-height: 1.6;
  color: #333;

  overflow-x: hidden;  /*가로 스크롤 사용하지 않음 */
  overflow-y: auto;
}

button{
  cursor: pointer;
  user-select: none; /*텍스트 선택 하지 않음*/
  &:active{
    transform: scale(0.98); /* 클릭 시 버튼이 약간 축소되는 효과 */
  }
}
div{
  user-select: none; /*텍스트 선택 하지 않음*/
}

input[type="text"]{
  background-color: #ffffff;
}

input[type="text"]::placeholder{
  color: #BBBBBB;
}

input[type="text"]:focus{
  outline: 1px solid #F0F2F7;
}



/* radio 버튼 기본 셋팅 */
:root {
  --selected-border-color: #388AF7;
  --default-radio-size: 16px;
  --disabled-border-color: #ccc; /* 비활성화된 상태의 테두리 색상 */
}

input[type="radio"] {
  appearance: none; /* 기본 라디오 버튼 스타일 제거 */
  width: var(--default-radio-size);
  height: var(--default-radio-size);
  border: 2px solid var(--disabled-border-color); /* 비활성화된 상태의 테두리 색상 */
  border-radius: 50%; /* 원형 모양 */
  background-color: #fff;
  cursor: pointer;
  position: relative;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

/* 선택된 상태에서 테두리 변경을 제거 */
input[type="radio"]:checked {
  border: 2px solid var(--selected-border-color); /* 선택된 상태에서 테두리 색상 유지 */
  background-color: #fff;
  box-shadow: none; /* 선택된 상태에서 box-shadow 제거 */
}

/* 라디오 버튼의 중간에 점 추가 */
input[type="radio"]:checked::after {
  content: ''; /* 점을 나타내기 위한 가상 요소 */
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px; /* 점의 크기 */
  height: 8px; /* 점의 크기 */
  border-radius: 50%; /* 점을 원형으로 */
  background-color: var(--selected-border-color); /* 점 색상 */
  transform: translate(-50%, -50%); /* 정확히 중앙에 위치 */
}

/* 비활성화된 라디오 버튼 스타일 */
input[type="radio"]:disabled {
  cursor: not-allowed; /* 비활성화 상태에서 커서 변경 */
  opacity: 0.5; /* 비활성화 상태에서 투명도 감소 */
  border: 2px solid var(--disabled-border-color); /* 비활성화된 상태의 테두리 색상 */
}

/* 포커스 시 강조 효과 */
input[type="radio"]:focus {
  outline: none;
  box-shadow: none; /* 포커스 효과 제거 */
}



textarea::placeholder{
  color: #BBBBBB;
}

textarea:focus{
  outline: 1px solid #F0F2F7;
}

/*input[type="text"]:focus{*/
/*  border: 1px solid #3b79fe;*/
/*}*/


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea{
  resize: none;
}

a {
  /*text-decoration-line: none;*/
  text-decoration: none;
}











/*공통 기능*/
.root_wrap{
  height: 100vh;

  /* 하위 정렬 속성*/
  display: flex;
  flex-direction: column;
}


.center{
  /* 내용 정렬하기 */
  display: flex;
  align-items: center;
}



/*React 경고창 비활성화*/
  #webpack-dev-server-client-overlay{
    display: none;
  }
