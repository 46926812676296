
.sec04_ctn {
  max-width: 1920px;
  min-height: 1000px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 50px;
}



.sec04_ctn .sec04_title_box {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 10px;

  & .main_title{
    font: normal 600 40px "Pretendard";
    color: #000000;
    line-height: 60px;
  }
  & .main_title::before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 40px;
    margin-right: 15px;

    background-image: url(../../assets/images/img_icon_lt_quotation_001.svg) ;
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-position: top center; /* 위쪽 정렬 및 수평 가운데 정렬 */
    background-size: contain;
  }
  & .main_title::after {
    content: '';
    display: inline-block;
    width: 26px;
    height: 40px;
    margin-left: 15px;

    background-image: url(../../assets/images/img_icon_rt_quotation_001.svg) ;
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-position: top center; /* 위쪽 정렬 및 수평 가운데 정렬 */
    background-size: contain;
  }


  & .sub_title{
    width: 100%;
    font: normal 400 18px "Pretendard";
    color: #343434;
    line-height: 27px;

    display: flex;
    justify-content: center;

    & span{
      font-weight: 600;
      margin-left: 5px;
    }
  }

}


.sec04_content {}

.sec04_content .masonryGrid {
  //max-width: calc(342px * 4 + 20px * 3); /* 최대 4개의 열과 3개의 간격 */

  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  //width: auto;
  width: 100%;
}

.masonryCol {
  background-clip: padding-box;


  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.masonryBox {

  width: 342px;
  box-shadow: -4px -4px 4px 0px #d7d7d740 inset;
  border-radius: 12px;
  background-color: #fafafa;

  font: normal 500 15px "Pretendard";
  color: #343434;
  line-height: 22px;

  padding: 25px;

  margin-bottom: 30px;

  & hr {
    width: 100%;
    border: none;
    border-top: 1px solid #E9E9E9; /* 두께와 색상 */
    margin: 10px 0; /* 위아래 여백 */
  }

  & p{
    margin: 0;
  }
}



.sec04_content .downInfoBar{
  margin-top: 100px;

  padding: 45px 40px;

  max-width: 1440px;
  height: 213px;
  //background-image:  url(../../assets/images/sec04_bg_download_001.svg);
  background-image:  url(../../assets/images/sec04_bg_download_001.png);

  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto;

  background-color: #4264EC;

  border-radius: 12px;
  border: none;

  //opacity: 0;
}

.downInfoBar .downInfo_sub_title{
  font: normal 300 16px "Pretendard";
  color: #FFFFFF;
  line-height: 20px;
}

.downInfoBar .downInfo_main_title{
  font: normal 600 32px "Pretendard";
  color: #FFFFFF;
  line-height: 40px;
}

.downInfoBar .btnGrp{

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 30px;

  gap: 15px;

  & .btn_google{
    width: 169px;
    height: 40px;

    background-image:  url(../../assets/images/img_btn_google_001.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff66;

    border: none;
    border-radius: 8px;
  }

  & .btn_appStore{
    width: 156px;
    height: 40px;

    background-image:  url(../../assets/images/img_btn_appStore_001.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff66;

    border: none;
    border-radius: 8px;
  }
}