
.sec03_ctn{
  min-height: 1000px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 50px;
}

.sec03_ctn .sec03_title_box {
  width: 445px;
  height: 60px;

  background: url(../../assets/images/sec03_title_001.svg) no-repeat center top;
}

.sec03_content{
  width: 100%;
}

.sec03_content .slide_box{

  height: 630px;
  background-color: #fafafa;
  box-shadow: inset 0 90px #ffffff, inset 0 -90px #ffffff; /* 상하 영역 덮기 */

  & .swiper{
    max-width: 1920px;
  }

  & .swiperSlide{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & .slide_cont{
    max-width: 1440px;
    width: 100%;
    height: 630px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  & .slide_cont .cont_textBox{

    width: 50%;
    height: 100%;

    padding-left: 120px;
    //padding-left: auto;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    gap: 30px;
  }
  & .slide_cont .cont_textBox h3 {
    font: normal 300 40px "Pretendard";
    color: #4264EC;
    line-height: 48px;
  }

  & .slide_cont .cont_textBox h4 {
    font: normal 600 32px "Pretendard";
    color: #000000;
    line-height: 38px;
  }

  & .slide_cont .cont_textBox p {
    font: normal 400 18px "Pretendard";
    color: #343434;
    line-height: 27px;
    & span{
      font-weight: 600;
    }
  }

  & .slide_cont .cont_textBox .nav_bar{

    margin-top: 30px;

    font: normal 400 20px "Pretendard";
    color: #343434;
    line-height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 10px;

    & span{
      font-weight: 600;
    }

    & button{
      border: none;
      background-color: transparent;
    }

  }


  & .slide_cont .cont_img{
    width: 50%;
    height: 100%;

    & .cont_img_bg01{
      width: 858px;
      height: 572px;
      background: url(../../assets/images/sec03_slide_img_001.png) no-repeat center top;
    }
    & .cont_img_bg02{
      width: 606px;
      height: 628px;
      background: url(../../assets/images/sec03_slide_img_002.png) no-repeat center top;
    }
    & .cont_img_bg03{
      width: 606px;
      height: 628px;
      background: url(../../assets/images/sec03_slide_img_003.png) no-repeat center top;
    }
    & .cont_img_bg04{
      width: 506px;
      height: 572px;
      background: url(../../assets/images/sec03_slide_img_004.png) no-repeat center top;
    }
    & .cont_img_bg05{
      width: 696px;
      height: 572px;
      background: url(../../assets/images/sec03_slide_img_005.png) no-repeat center top;
    }
    & .cont_img_bg06{
      width: 602px;
      height: 628px;
      background: url(../../assets/images/sec03_slide_img_006.png) no-repeat center top;
    }
    & .cont_img_bg07{
      width: 584px;
      height: 628px;
      background: url(../../assets/images/sec03_slide_img_007.png) no-repeat center top;
    }
  }

}