.root_wrap {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    overflow: visible;
    //overflow-y: hidden;
}

.sec01_wrap{
    width: 100%;
    min-height: 1000px;
}

.sec02_wrap{
    min-height: 1000px;
    width: 100%;
}

.sec03_wrap{
    min-height: 1000px;
    width: 100%;
}

.sec04_wrap{
    max-width: 1920px;
    min-height: 1000px;
    width: 100%;
}

.sec05_wrap{
    //max-width: 1920px;
    min-height: 1000px;
    width: 100%;
}

.sec06_wrap{
    max-width: 1920px;
    min-height: 1000px;
    width: 100%;
}