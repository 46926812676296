
.sec03_ctn{
  min-height: 450px;
  max-width: 640px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 50px;
}

.sec03_ctn .sec03_title_box {

  margin: 52px 20px 0 20px;

  width: 235px;
  height: 33px;

  background: url(../../assets/images/sec03_title_001.svg) no-repeat center top;
  background-size: var(--svg-width, 235px) var(--svg-height, 33px);
}

.sec03_content{
  width: 100%;
}

.sec03_content .slide_box{

  height: 570px;
  background-color: #fafafa;
  box-shadow: inset 0 35px #ffffff;

  & .swiper{
    max-width: 640px;
  }

  & .swiperSlide{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & .slide_cont{
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 16px;
  }
  & .slide_cont .cont_textBox{

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  & .slide_cont .cont_textBox h3 {
    font: normal 300 20px "Pretendard";
    line-height: 24px;
    color: #4264EC;
  }

  & .slide_cont .cont_textBox h4 {

    margin-top: 6px;

    font: normal 700 20px "Pretendard";
    line-height: 24px;
    color: #000000;
  }

  & .slide_cont .cont_textBox p {

    margin-top: 16px;

    font: normal 400 15px "Pretendard";
    line-height: 27px;
    color: #343434;
    text-align: center;

    & span{
      font-weight: 600;
    }
  }

  & .slide_cont .cont_textBox .nav_bar{

    margin: 30px 0 42px 0;

    font: normal 400 16px "Pretendard";
    line-height: 24px;
    color: #343434;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 10px;

    & span{
      font-weight: 600;
    }

    & button{
      border: none;
      background-color: transparent;
    }
  }

  & .slide_cont .cont_img{
    width: 100%;
    height: 342px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .cont_img_bg01{
      width: 275px;
      height: 326px;
      background: url(../../assets/images/sec03_slide_img_m_001.png) no-repeat center top;
      //background-size: contain;
    }
    & .cont_img_bg02{
      width: 334px;
      height: 342px;
      background: url(../../assets/images/sec03_slide_img_m_002.png) no-repeat center top;
      //background-size: contain;
    }
    & .cont_img_bg03{
      width: 334px;
      height: 342px;
      background: url(../../assets/images/sec03_slide_img_m_003.png) no-repeat center top;
      //background-size: contain;
    }
    & .cont_img_bg04{
      width: 241px;
      height: 326px;
      background: url(../../assets/images/sec03_slide_img_m_004.png) no-repeat center top;
      //background-size: contain;
    }
    & .cont_img_bg05{
      width: 274px;
      height: 326px;
      background: url(../../assets/images/sec03_slide_img_m_005.png) no-repeat center top;
      //background-size: contain;
    }
    & .cont_img_bg06{
      width: 333px;
      height: 342px;
      background: url(../../assets/images/sec03_slide_img_m_006.png) no-repeat center top;
      //background-size: contain;
    }
    & .cont_img_bg07{
      width: 329px;
      height: 342px;
      background: url(../../assets/images/sec03_slide_img_m_007.png) no-repeat center top;
      //background-size: contain;
    }
  }

}