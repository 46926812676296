
.sec06_cnt{
  max-width: 1920px;
  width: 100%;
  min-height: 1000px;

  margin: 0 auto; // 가운데 정렬
  padding: 24px;
}


.sec06_title_box {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 10px;
}
.sec06_title_box .main_title{
  font: normal 900 48px "Pretendard";
  color: #388AF7;
  line-height: 60px;
}

.sec06_title_box .sub_title{
  font: normal 400 18px "Pretendard";
  color: #343434;
  line-height: 27px;

  span{
    font-weight: 600;
  }
}


.sec06_content{

  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  max-width: 1196px;
  flex-direction: column;
  margin: 0 auto 0 auto; // 중앙 정렬
}

.sec06_content hr {
  width: 100%;
  border: none;
  border-top: 1px solid #F0F2F7; /* 두께와 색상 */
  margin: 24px 0; /* 위아래 여백 */
}


.sec06_content .inputArea{

  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, minmax(50%, auto));
  grid-template-rows: repeat(4, minmax(50px,auto));
  grid-auto-flow: dense;

  background-color: #fafafa;

  padding: 35px 40px;

  gap: 20px;

  &_item{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    gap: 3px;
  }

  &_item h3{
    font: normal 600 16px "Pretendard";
    color: #343434;
    line-height: 24px;
  }
  &_item h3::after{
    content: '*';
    display: inline-block;
    width: 15px;
    height: 27px;
    margin-right: 15px;

    font: normal 400 18px "Pretendard";
    color: #4264EC;
    text-align: center;
    line-height: 27px;
  }

  &_item:nth-child(n+5){
    grid-column: 1 / -1;
  }

  input[type="text"]{
    width: 100%;
    height: 44px;
    padding: 0 0 0 16px;

    background-color: #ffffff;
    border-radius: 4px;
    border: none;

    font: normal 400 16px "Pretendard";
    color: #343434;
    line-height: 24px;
  }

  textarea{
    width: 100%;
    height: 240px;

    padding: 16px;

    background-color: #ffffff;
    border-radius: 4px;
    border: none;

    font: normal 400 16px "Pretendard";
    color: #343434;
    line-height: 24px;
  }

  .fieldRadio{
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    flex-wrap: wrap;

    column-gap: 32px;
    row-gap: 10px;

    .radioCont {
      display: flex;
      align-items: center;
    }

    input[type="radio"] {
      margin-right: 8px;
    }

    label{
      cursor: pointer;
      font: normal 500 16px "Pretendard";
    }
  }
}

.sec06_content .termsArea{
  width: 100%;

  margin: 32px 0 120px 0;
}


.sec06_content .termsArea .termsAreaCtl{

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  font: normal 600 14px "Pretendard";
  line-height: 18px;
  color: #343434;

  border-top: 1px solid #F0F2F7;
  border-bottom: 1px solid #F0F2F7;

  padding: 30px 24px;

  label[for="termsChk"] {
    position: relative;
    top: -1px;
    margin-left: 5px;
  }

  button{
    text-align: left;
    margin-left: auto;

    background-color: #FFFFFF;
    border: none;
  }
}

.sec06_content .termsArea .termsCont{

  background-color: #fafafa;
  padding: 24px 16px;

  h2, p ,li {
    font: normal 400 16px "Pretendard";
    line-height: 24px;
    color: #343434;
    & span {
      font-weight: 600;
    }
  }
  ul {
    margin-left: 20px;
    margin-bottom: 16px;
  }
}


.sec06_content .termsArea .btnGrp{
  width: 100%;

  margin-top: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  .contact_us{
    width: 150px;
    height: 46px;
    padding: 8px 0;
    border-radius: 4px;

    font: normal 600 20px "Pretendard";
    color: #FFFFFF;

    background-color: #BBBBBB;

    border: none;
  }
}






//모바일 페이지 통합 (추후 테블릿 분리 필요)
@media (max-width: 640px) {
  .sec06_title_box{
    margin: 64px 5px 0 5px;
  }

  .sec06_title_box .main_title{
    font: normal 900 22px "Pretendard";
    line-height: 22px;
    color: #388AF7;
  }

  .sec06_title_box .sub_title{
    font: normal 400 15px "Pretendard";
    line-height: 22px;
    color: #343434;

    text-align: center;

    span{
      font-weight: 600;
    }
  }
  .sec06_content{
    margin: 0 auto 0 auto; // 중앙 정렬
  }

  .sec06_content .inputArea {
    grid-template-columns: repeat(1, minmax(100%, auto));
    grid-template-rows: repeat(5, minmax(50px, auto));

    padding: 16px 16px;

    gap: 16px;

    .fieldRadio {
      flex-direction: column;
    }
  }

  .sec06_content .termsArea{
    width: 100%;

    margin: 16px 0 80px 0;
  }

  .sec06_content .termsArea .termsAreaCtl{
    padding: 16px 8px;
  }

  .sec06_content .termsArea .termsAreaCont{

    background-color: #fafafa;
    padding: 8px;

    h2, p ,li {
      font: normal 400 14px "Pretendard";
      line-height: 24px;
      color: #343434;
      span {
        font-weight: 600;
      }
    }
    ul {
      margin-left: 20px;
      margin-bottom: 16px;
    }
  }
}