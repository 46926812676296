
.sec01_root{
  background-color: #fafafa;
}

.sec01_ctn {
  max-width: 640px;
  width: 100%;

  min-height: 450px;

  margin: 0 auto; // 가운데 정렬

  position: relative;

  display: flex;
  justify-content: flex-start;
  //align-items: flex-start;
  flex-direction: column;

  background-image:
          url("../../assets/images/sec01_bg_plus_001.svg"), //녹색
          url("../../assets/images/sec01_bg_plus_002.svg"), //빨강색
          url("../../assets/images/sec01_bg_plus_003.svg"), //녹색(큰거)
          url("../../assets/images/sec01_bg_plus_004.svg"); //노란색
  background-size:
          var(--svg-width, 120px) var(--svg-height, 120px),
          var(--svg-width, 50px) var(--svg-height, 50px),
          var(--svg-width, 220px) var(--svg-height, 220px),
          var(--svg-width, 100px) var(--svg-height, 100px);
  background-position:
          max(calc(50% - 400px), 0%) calc(50% - 250px), // X Y
          min(calc(50% + 60px), 100%) calc(50% - 80px),
          min(calc(50% + 820px), 100%) calc(50% + 50px),
          min(calc(50% + 430px), 100%) calc(50% - 250px);
  background-repeat: no-repeat;


}

.title_box{

  //width: 100%;
  margin: 52px 20px 0 20px;
  display: flex;
  justify-content: center; // 수평
  align-items: center; // 수직

  flex-direction: column;
  gap: 5px;

  & .main_title{

    font: normal 800 36px "Pretendard";
    line-height: 43px;
    color: #000000;

    display: flex;
    justify-content: center; // 수평
  }

  & .sub_title{

    font: normal 500 15px "Pretendard";
    line-height: 22px;
    color: #343434;
    text-align: center;

    display: flex;
    justify-content: center; // 수평
  }
}


.box_container {

  //margin-top: 50px;

  position: relative;
  top : 50px;
  width: 100%;
  //height: 400px;
  overflow: visible;
}

.box_container .box {
  position: absolute;

  width: 160px;
  height: 156px;
}

.box_container .box .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}


.sec01_scroll{
  position: absolute;
  bottom: 0;

  left: 50%; /* 부모 요소 기준으로 가로 중간 */
  transform: translateX(-50%); /* 가로 중간 보정 */

  width: 120px;
  height: 60px;

  background: url(../../assets/images/sec01_btn_scroll_001.svg) no-repeat center top;
  background-size: var(--svg-width, 120px) var(--svg-height, 60px)

}