
.sec01_root{
  background-color: #fafafa;
}

.sec01_ctn {
  max-width: 1920px;
  width: 100%;

  min-height: 1000px;

  margin: 0 auto; // 가운데 정렬

  position: relative;

  display: flex;
  justify-content: flex-start;
  //align-items: flex-start;
  flex-direction: column;

  background-image:
          url("../../assets/images/sec01_bg_plus_001.svg"),
          url("../../assets/images/sec01_bg_plus_002.svg"),
          url("../../assets/images/sec01_bg_plus_003.svg"),
          url("../../assets/images/sec01_bg_plus_004.svg");
  background-position:
          max(calc(50% - 770px), 0%) calc(50% - 300px), // X Y
          min(calc(50% + 430px), 100%) calc(50% - 180px),
          min(calc(50% + 820px), 100%) calc(50% + 70px),
          min(calc(50% + 970px), 100%) calc(50% - 400px);
  background-repeat: no-repeat;

}

.title_box{

  width: 100%;
  margin-top: 150px;
  display: flex;
  justify-content: center; // 수평
  align-items: center; // 수직

  flex-direction: column;
  gap: 20px;

  & .main_title{

    width: 100%;

    font: normal 800 80px "Pretendard";
    color: #000000;
    line-height: 95px;

    display: flex;
    justify-content: center; // 수평
  }

  & .sub_title{

    width: 100%;
    font: normal 500 24px "Pretendard";
    color: #343434;
    line-height: 36px;

    display: flex;
    justify-content: center; // 수평
  }
}





.box_container {

  margin-top: 50px;

  position: relative;
  top : 80px;
  width: 100%;
  height: 400px;
  overflow: visible;
}

.box_container .box {
  position: absolute;

  width: 342px;
  height: 342px;
}

.box_container .box .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}


.sec01_scroll{
  position: absolute;
  bottom: 0;

  left: 50%; /* 부모 요소 기준으로 가로 중간 */
  transform: translateX(-50%); /* 가로 중간 보정 */

  width: 250px;
  height: 125px;

  background: url(../../assets/images/sec01_btn_scroll_001.svg) no-repeat center top;
}