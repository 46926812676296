.footer{
  background-color: #222222;
  height: 220px;
  overflow-y: hidden;
}

.footer_ctn{
  max-width: 1440px;
  width: 100%;

  margin: 0 auto; // 가운데 정렬
  padding: 48px 0 78px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1920px) {
    padding: 48px 24px 48px;
  }
}


.logoBox{
  width:140px;
  height:18px;

  background: url(../../assets/images/HEALTH_SCAN_CI_002.svg) no-repeat left top;
}

.companyInfo{
  margin-top: 24px;

  display: flex;
  flex-direction: row;
  gap: 20px;
}

.infoBox01{
  font: normal 400 15px "Pretendard";
  line-height: 30px;
  color: #BBBBBB;
}

.infoBox02{
  margin-left: auto;
  margin-top: auto;

  font: normal 300 14px "Pretendard";
  line-height: 30px;
  color: #BBBBBB;

  display: flex;
  flex-direction: row;
  gap: 15px;
}


@media (max-width: 900px) {
  .footer_ctn {
    padding: 32px 24px; // 화면 크기가 1920px보다 작을 때 패딩을 24px로 설정
  }

  .companyInfo{
    flex-direction: column;
  }

  .infoBox02 {
    margin-left: 0;
  }
}

@media (max-width: 640px) {
  .footer_ctn {
    padding: 32px 24px; // 화면 크기가 1920px보다 작을 때 패딩을 24px로 설정
  }

  .companyInfo{
    flex-direction: column;
  }

  .infoBox01{
    font: normal 500 13px "Pretendard";
    line-height: 20px;
    color: #BBBBBB;
  }

  .infoBox02{
    margin-left: 0;

    font: normal 2400 13px "Pretendard";
    line-height: 20px;
    color: #BBBBBB;

    display: flex;
    flex-direction: column;
    gap: 8px;

    & .partition{
      display: none;
    }
  }
}

