// FadeIn.module.scss
.fade-in-up {
  opacity: 0;
  transform: translateY(20px); /* 아래에서 위로 */
  animation: fadeInUp 1s ease-out forwards;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-down {
  opacity: 0;
  transform: translateY(-20px); /* 위에서 아래로 */
  animation: fadeInDown 1s ease-out forwards;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-20px); /* 왼쪽에서 오른쪽으로 */
  animation: fadeInLeft 1s ease-out forwards;

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-right {
  opacity: 0;
  transform: translateX(20px); /* 오른쪽에서 왼쪽으로 */
  animation: fadeInRight 1s ease-out forwards;

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-scale {
  opacity: 0;
  transform: scale(0.5); /* 크기 확대/축소 */
  animation: fadeInScale 1s ease-out forwards;

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
