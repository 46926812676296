.sec02_ctn {
  min-height: 450px;
  max-width: 640px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sec02_title_box {

  margin: 64px 20px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;

  .main_title {
    font: normal 600 22px "Pretendard";
    line-height: 30px;
    color: #000000;
  }

  .sub_title {
    width: 100%;
    font: normal 400 15px "Pretendard";
    color: #343434;
    line-height: 27px;
    text-align: center;

    span {
      font-weight: 600;
    }
  }
}

.cardSB_cont {
  position: relative;
  margin: 24px 0;
  padding: 0 16px;
  width: 100%;

  &::before { //배경에 줄긋기 추후 변경 필요
    content: "";
    position: absolute;
    top: 6px; // 고정된 위치를 픽셀로 지정
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E1E1E1;
    z-index: -1;
  }
}

.scroll_cont {
  display: flex;
  gap: 16px;
  cursor: grab;
}

.card {
  flex: 0 0 auto;  /* 고정 크기 */
  width: 240px;
  text-align: center;

  img {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
