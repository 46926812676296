.sec02_ctn{
  //max-width: 1920px;
  height: 1000px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sec02_ctn .sec02_title_box{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 10px;

  & .main_title{
    font: normal 600 40px "Pretendard";
    color: #000000;
    line-height: 60px;
  }

  & .sub_title{
    width: 100%;
    font: normal 400 18px "Pretendard";
    color: #343434;
    line-height: 27px;

    & span{
      font-weight: 600;
    }
  }
}

.cardSB_cont {
  position: relative;
  margin: 48px 0;
  padding: 0 16px;
  width: 100%;

  display: flex;
  justify-content: center;

  &::before { //배경에 줄긋기 추후 변경 필요
    content: "";
    position: absolute;
    top: 6px; // 고정된 위치를 픽셀로 지정
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E1E1E1;
    z-index: -1;
  }
}

.scroll_cont {
  display: flex;
  gap: 24px;
  cursor: grab;
}

.card {
  flex: 0 0 auto;  /* 고정 크기 */
  width: 342px;
  text-align: center;

  img {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
