
.sec05_cnt{

  min-height: 1000px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 20px;
}

.sec05_cnt .sec05_title_box{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  row-gap: 10px;

  & .main_title{
    font: normal 600 40px "Pretendard";
    color: #000000;
    line-height: 60px;
    & span{
      color: #388AF7;
    }
  }

  & .sub_title{
    width: 100%;
    font: normal 400 18px "Pretendard";
    color: #343434;
    line-height: 27px;

    & span{
      font-weight: 600;
    }
  }
}
.sec05_content{
  width: 100%;
}

.sec05_content .slide_box {
  height: 500px;
  background-color: #f0f2f7;
  box-shadow: inset 0 25px #ffffff, inset 0 -25px #ffffff; /* 상하 영역 덮기 */
}

.sec05_content .slide_box .swiper{
  max-width: 1920px;
}

.sec05_content .slide_box .swiperSlide{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sec05_content .slide_box .slide_cont{
  max-width: 1440px;
  height: 500px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.sec05_content .slide_box .slide_cont .cont_textBox{

  width: 50%;
  height: 100%;

  padding-left: 130px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  gap: 40px;

  & .messBoxBf{
    //width: 586px;
    width: 100%;
    max-width: 586px;
    height: 65px;
    background-color: #FAFAFA;
    border-radius: 0 0 12px 12px;
  }
  & .messBoxAf{
    //width: 586px;
    width: 100%;
    max-width: 586px;
    height: 65px;
    background-color: #FAFAFA;
    border-radius: 12px 12px 0 0;

    font: normal 700 26px "Pretendard";

    padding: 32px 40px 0 32px;
  }

  & .messBox {
    position: relative;
    //width: 586px;
    width: 100%;
    max-width: 586px;
    height: 240px;
    border-radius: 12px;
    background-color: #4264EC;

    color: #FFFFFF;
    line-height: 27px;
    padding: 32px 40px;

    & h3{
      font: normal 700 26px "Pretendard";
    }

    & hr {
      width: 100%;
      border: none;
      border-top: 1px solid #E9E9E980; /* 두께와 색상 */
      margin: 16px 0; /* 위아래 여백 */
    }

    & p {
      font: normal 400 18px "Pretendard";
      line-height: 27px;

      & span{
        font-weight: 600;
      }
    }
  }

  & .circle_with_triangle {
    position: absolute;
    top: 50%;
    left: -116px; /* 삼각형과 동그라미 사이의 간격을 30px 늘림 */
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    /* 동그라미 */
    & .circle {

      width: 64px;
      height: 64px;

      background-color: #4264EC;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      font: normal 600 20px "Pretendard";
      color: #FFFFFF;
    }

    /* 삼각형 */
    & .triangle {
      width: 0;
      height: 0;

      border-top: 23px solid transparent;
      border-bottom: 23px solid transparent;
      border-right: 36px solid #4264EC;

      margin-left: 20px; /* 동그라미와 삼각형 사이에 30px의 여백을 추가 */
    }
  }

}

.sec05_content .slide_box .slide_cont .cont_right{
  width: 50%;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  & div{
    margin-top: 50px;
    width: 708px;
    height: 500px;
  }

  & .cont_right_bg01{
    background: url(../../assets/images/sec05_slide_img_001.png) no-repeat center top;
  }
  & .cont_right_bg02{
    background: url(../../assets/images/sec05_slide_img_002.png) no-repeat center top;
  }
  & .cont_right_bg03{
    background: url(../../assets/images/sec05_slide_img_003.png) no-repeat center top;
  }
}


.sec05_content .slide_box .nav_bar{

  margin-top: 80px;

  font: normal 400 20px "Pretendard";
  color: #343434;
  line-height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  gap: 10px;

  & span{
    font-weight: 600;
  }

  & button{
    border: none;
    background-color: transparent;
  }

}
